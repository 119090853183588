export default {
    data() {
        return {
            feature: [{
                title: '规范实习的组织和管理',
                numberPic: require("../../assets/icons/num1.png"),
                text: '通过标准化实习管理平台的推广使用，规范化学校的实习组织和管理模式，通过平台实现专业设置与产业需求对接，课程内容与职业标准对接，教学过程与生产过程对接，毕业证书与职业资格证书对接，职业教育与终身学习对接，提高人才培养质量和针对性。'
            }, {
                title: '多维交互联动贯穿学生实习就业全流程',
                numberPic: require("../../assets/icons/num2.png"),
                text: '贯穿学生实习整个流程，通过实习前（实习双选、实习计划、三方协议、岗位安排）、实习中（指导老师企业带教老师双导师指导、日常管理、实训或者实习进展、安全预警、考勤打卡、实习档案等）、实习后（校企评价、就业双选、就业追踪，就业统计、质量报告等），通过学校、企业、学生多角色、多角度利用现代主流信息技术手段线上交互联动。'
            }, {
                title: '强化实习环节、增强实践育人的导向',
                numberPic: require("../../assets/icons/num3.png"),
                text: '通过平台，有效的加强了实习过程中学校与企业的双主体协同育人责任，促进学生职业技能成长、职业素养提升。'
            }, {
                title: '线上就业双选，给企业和学生精准匹配',
                numberPic: require("../../assets/icons/num4.png"),
                text: '透过多样合作方式与交流功能突出院校、 企业以及个人之间三者的衔接与交叉，能够切实为校企人才合作 提供帮助。为企业找到好人才，为学生找到好工作，为学校提高实习就业率。同时在就业实习管理过程中建立安全稳定的企业信息库和岗位库，平台所有企业均通过工商信息信息认证。'
            }, {
                title: '大数据支撑实习安全保障和科学决策',
                numberPic: require("../../assets/icons/num4.png"),
                text: '系统基于大数据支撑建立危机预警机制，如学生签到预警、日周月报预警、企业工商信息预警、企业职位智能推荐、学生智能推荐等等功能，为学生、教师、管理者、企业提供全方位安全保障和决策依据。'
            }],
        }
    },
    created() {

    },
}