<template>
  <div class="sshx">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>中高职学生实习与就业平台</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />我们的优势
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />中高职学生实习与就业平台
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img
                      src="../../assets/resource/sshx.png"
                      alt=""
                    /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img
                        src="../../assets/icons/separater.png"
                        alt=""
                      /></span>
                  </div>
                  <h2>中高职学生实习与就业平台</h2>
                </div>
                <div class="text">
                  <p>中高职学生实习与就业平台是中高职院校实习就业一体化平台，平台创新实习就业工作模式，利用大数据及人工智能助力师徒现代学徒制实习与就业，破解各类风险挑战，
                    实现实习生高质量融合教育培养、实现毕业生更充分更高质量就业，平台能够高效对接企业用工需求，实现求职信息和用人单位的岗位信息有效匹配，平台以学生成长发展为主线，
                    整合高校、企业和社会就业资源，搭建了一个数字化“高职院校实习就业大市场”，促进就业资源共享、提供更加全面、更加优质的就业信息和指导服务。</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col
            :xl="8" :lg="8" :md="12" :sm="24"
            v-for="(item,index) in feature"
            :key="index"
          >
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{item.title}}</h3>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
          </Col>
        </Row>

        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zgzpt1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zgzpt2.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zgzpt3.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import zgzpt from "./zgzpt.js";
export default zgzpt;
</script>

<style lang="less" scoped>
@import "./zgzpt.less";
</style>